<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{
            (isAdd ? $t("actions.add") : $t("actions.edit")) +
            " " +
            $tc("dahua_dvr.title", 1)
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="tenant"
            rules="required"
          >
            <b-form-group
              label="Tenant"
              label-for="tenant_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_tenant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="tenant_id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('dahua_dvr.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ip -->
          <validation-provider #default="validationContext" name="ip" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('dahua_dvr.ip')"
              label-for="ip"
            >
              <b-form-input
                id="ip"
                v-model="itemData.ip"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- user -->
          <validation-provider #default="validationContext" name="user" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('dahua_dvr.user')"
              label-for="ip"
            >
              <b-form-input
                id="user"
                v-model="itemData.user"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-input-group
              class="text-capitalize"
              :label="$t('dahua_dvr.password')"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="itemData.password"
                :state="getValidationState(validationContext)"
                trim
                :type="passwordFieldType"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  color="#000"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-input-group>
          </validation-provider>

          <!-- http schema -->
          <validation-provider
            #default="validationContext"
            rules="required"
            :name="$t('dahua_dvr.http_schema')"
            class="d-block mt-2"
          >
            <b-form-group
              :label="$t('dahua_dvr.http_schema')"
              label-for="http_schema"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.http_schema"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="httpSchemaOptions"
                :clearable="false"
                input-id="http_schema"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- http port -->
          <validation-provider
            #default="validationContext"
            rules="required"
            :name="$t('dahua_dvr.http_port')"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('dahua_dvr.http_port')"
              label-for="http_port"
            >
              <b-form-input
                id="http_port"
                v-model="itemData.http_port"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- rtsp schema -->
          <validation-provider
            #default="validationContext"
            rules="required"
            :name="$t('dahua_dvr.rtsp_schema')"
          >
            <b-form-group
              :label="$t('dahua_dvr.rtsp_schema')"
              label-for="rtsp_schema"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.rtsp_schema"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rtspSchemaOptions"
                :clearable="false"
                input-id="rtsp_schema"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- rtsp port -->
          <validation-provider
            #default="validationContext"
            rules="required"
            :name="$t('dahua_dvr.rtsp_port')"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('dahua_dvr.rtsp_port')"
              label-for="rtsp_port"
            >
              <b-form-input
                id="rtsp_port"
                v-model="itemData.rtsp_port"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    tenantOptions: {
      type: Array,
      required: true,
    },

    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {
      name: "",
      description: "",
      user: "",
      password: "",
      ip: "",
      id_tenant: null,
      http_port: null,
      rtsp_port: null,
      http_schema: "http",
      rtsp_schema: "rtsp",
    };

    const passwordFieldType = ref("password");
    const passwordToggleIcon = computed(() => {
      return passwordFieldType.value === "password" ? "EyeIcon" : "EyeOffIcon";
    });
    const httpSchemaOptions = ["http", "https"];
    const rtspSchemaOptions = ["rtsp", "rtsps"];

    const togglePasswordVisibility = () => {
      passwordFieldType.value =
        passwordFieldType.value === "password" ? "text" : "password";
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        dispatch = store.dispatch("dahua_dvr/add", itemData.value);
      } else {
        const req = {
          id: itemData.value.id,
          params: itemData.value,
        };

        dispatch = store.dispatch("dahua_dvr/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
      }
    };

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      loadData,
      resetForm,

      passwordFieldType,
      passwordToggleIcon,
      httpSchemaOptions,
      rtspSchemaOptions,
      togglePasswordVisibility,
    };
  },
};
</script>